<template>
  <QDialog
    v-model="authStore.showLoginModal"
    persistent
  >
    <div
      class="relative w-full h-110 md:(w-lg h-100) bg-gradient-to-r from-[#245647] to-black overflow-hidden"
    >
      <div class="py-4 flex justify-center">
        <Logo
          class="w-1/3"
          :dark="$q.dark.isActive"
        />
        <QBtn
          v-close-popup
          icon="close"
          to="/"
          flat
          round
          dense
          class="absolute top-2 right-2"
        />
      </div>
      <div
        v-if="$q.screen.gt.sm"
        class="bg-cover absolute -top-0 -left-33 w-full h-full"
        :style="{'background-image': `url(${bgImg})`}"
      />
      <div class="absolute w-full md:(w-lg px-8) p-4">
        <QForm
          class="grid w-full"
          @submit.prevent="authStore.login(form)"
        >
          <div class="flex flex-col items-center md:items-end gap-4 w-full">
            <div class="flex flex-col md:(flex-row w-auto gap-6 items-center) gap-2 w-full">
              <div>
                USERNAME
              </div>
              <QInput
                v-model="form.username"
                placeholder="아이디를 입력하세요"
                outlined
                class="w-full md:w-60"
                :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
              />
            </div>
            <div class="flex flex-col md:(flex-row w-auto gap-6 items-center) gap-2 w-full">
              <div>
                PASSWORD
              </div>
              <QInput
                v-model="form.password"
                placeholder="비밀번호를 입력하세요"
                outlined
                class="w-full md:w-60"
                :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
                type="password"
              />
            </div>
            <QBtn
              label="LOGIN"
              color="secondary"
              text-color="black"
              unelevated
              type="submit"
              class="w-full md:w-60 h-13 font-bold text-sm"
              :loading="authStore.logging"
            />
            <QBtn
              label="SIGN UP"
              color="secondary"
              text-color="black"
              unelevated
              class="w-full md:w-60 h-13 font-bold text-sm"
              :to="{name:'registration'}"
            />
          </div>
        </QForm>
      </div>
    </div>
  </QDialog>
</template>

<script setup>
import bgImg from '@/assets/login/bg.png'
import Logo from '@/components/Logo.vue'
import { useAuthStore } from '@/stores/auth.js'
import { reactive } from 'vue'

const authStore = useAuthStore()

const form = reactive({
  username: '',
  password: ''
})
</script>