<template>
  <QDialog
    v-model="authStore.showRegistrationModal"
    persistent
  >
    <div class="rounded-md border-2 border-dark-1 w-full md:w-100">
      <QForm @submit.prevent="submit">
        <div class="h-15 bg-dark-1 flex items-center justify-center relative">
          가입하기
          <QBtn
            v-close-popup
            to="/"
            icon="close"
            flat
            dense
            class="absolute top-0 right-0 bg-dark-3 h-15 w-15"
          />
        </div>
        <div class="p-5 grid gap-2 bg-dark-3">
          <div class="flex gap-2">
            아이디
            <QInput
              v-model="form.username"
              dense
              outlined
              hint="영어,숫자 4글자이상"
              :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
              class="w-full border-dark-2"
            />
          </div>
          <div class="flex gap-2">
            닉네임
            <QInput
              v-model="form.nickname"
              dense
              outlined
              hint="한글,영문 2글자이상"
              :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
              class="w-full"
            />
          </div>
          <div class="flex gap-2">
            비밀번호
            <QInput
              v-model="form.password"
              type="password"
              dense
              outlined
              hint="4~8자만 입력하세요"
              :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
              class="w-full"
            />
          </div>
          <div class="flex gap-2">
            로그인 비밀번호확인
            <QInput
              v-model="form.password_confirmation"
              type="password"
              dense
              outlined
              hint="비밀번호 확인"
              :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
              class="w-full"
            />
          </div>
          <div class="flex gap-2">
            휴대폰
            <div class="flex flex-nowrap items-start gap-2">
              <QInput
                v-model="form.mobile"
                dense
                outlined
                hint="본인 휴대폰 번호를 입력하세요"
                :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
                class="w-full"
              >
                <template #prepend>
                  <div class="text-sm">
                    {{ `+(${countryCode})` }}
                  </div>
                </template>
              </QInput>
            </div>
          </div>
          <div class="flex gap-2">
            예금주
            <QInput
              v-model="form.account_name"
              dense
              outlined
              hint="본인 이름을 입력하세요"
              :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
              class="w-full"
            />
          </div>
          <div class="flex gap-2">
            은행명
            <QSelect
              v-model="form.bank_id"
              :options="bankOptions"
              emit-value
              map-options
              dense
              outlined
              options-dense
              hint="은행명을 선택하세요 "
              :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
              class="w-full"
            />
          </div>
          <div class="flex gap-2">
            출금 계좌번호
            <QInput
              v-model="form.card_number"
              dense
              outlined
              hint="은행 계좌 번호를 입력하세요"
              :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
              class="w-full"
            />
          </div>
          <div class="flex gap-2">
            추천 코드
            <QInput
              v-model="form.invite_code"
              readonly
              dense
              outlined
              :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
              class="w-full"
            />
          </div>
        </div>
        <QBtn
          type="submit"
          color="primary"
          text-color="black"
          :loading="authStore.registering"
          class="w-full h-13"
        >
          가입하기
        </QBtn>
      </QForm>
    </div>
  </QDialog>
</template>

<script setup>
import {useAuthStore} from '@/stores/auth'
import {computed, onMounted, reactive, ref} from 'vue'
import {useRequest} from '@/composables/useRequest'
import {whenever} from '@vueuse/core'
import dayjs from "dayjs";

const authStore = useAuthStore()
const code = ref('')

const countryCode = '82'
const captchaTime = ref(60)
const num = ref(0)
const verifyBtnDisable = ref(false)
const sendVerifyBtnLabel = ref('인증번호발송')


const form = reactive({
  username: '',
  nickname: '',
  mobile: '',
  password: '',
  password_confirmation: '',
  bank_id: '',
  account_name: '',
  card_number: '',
  invite_code: code,
  // sms_code: '',
  // sms_request_id: ''
})
const { data: banks, execute: fetch } = useRequest('banks')
fetch()
const bankOptions = computed(() => banks.value?.data.map(bank => ({ label: bank.name, value: bank.id })))
const appName = computed(() => import.meta.env.VITE_APP_NAME)

async function submit(){
  await authStore.register(form)
  authStore.showRegistrationModal = false
  localStorage.removeItem('verify_start_time')
}

whenever(() => authStore.showRegistrationModal, () => {
  code.value = localStorage.getItem('code')
})

onMounted(() => {
  const startTime = localStorage.getItem('verify_start_time')
  num.value = dayjs().unix() - startTime
  captchaTime.value = num.value > 60 ? 60 : 60 - num.value
})

async function sendVerificationCode(){
  verifyBtnDisable.value = captchaTime.value !== 60

  if (captchaTime.value === 60){
    localStorage.setItem('verify_start_time', dayjs().unix().toString())

    const verifyForm = reactive({
      mobile: `${countryCode}${form.mobile}`,
      app_name: appName,
    })

    const verifyRequestId = localStorage.getItem('verify_request_id')
    if (verifyRequestId){
      let verifyRequestArr = verifyRequestId.split('_')
      if (dayjs().unix() - verifyRequestArr[1] < 1800){
        verifyForm.sms_request_id = verifyRequestArr[0]
      }
    }
    await authStore.verify(verifyForm)

    form.sms_request_id = authStore.verifyData.request_id
    localStorage.setItem('verify_request_id', `${authStore.verifyData.request_id}_${dayjs().unix().toString()}`)
  }

  sendVerifyBtnLabel.value = captchaTime.value.toString()
  const timer = setInterval(() => {
    if (captchaTime.value === 1){
      clearInterval(timer)
      captchaTime.value = 60
      sendVerifyBtnLabel.value = '인증번호재전송'
      verifyBtnDisable.value = false
    }else{
      captchaTime.value--
      sendVerifyBtnLabel.value = captchaTime.value.toString()
    }
  }, 1000)
}
</script>

<style scoped>

</style>
